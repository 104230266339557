import React, { Component } from 'react'
import Grid, { Col, BreakoutContent } from './Grid'
import Body from './Body'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'

export default class Paragraphs extends Component {
  render() {
    const { data } = this.props

    return data.map((item, index) => {
      const {
        body,
        relationships: {
          field_image: {
            localFile: {
              childImageSharp: { fluid: image },
            },
          },
        },
      } = item

      const flipped = index % 2 !== 0

      return (
        <div className="l-constrained" key={item.id}>
          <Grid flipped={flipped} top>
            <Col>
              <BreakoutContent
                breakoutLeft={!flipped && 1}
                breakoutRightt={flipped && 1}
              >
                <Img fluid={image} />
              </BreakoutContent>
            </Col>
            <Col padded flat>
              <Body dangerouslySetInnerHTML={{ __html: body.processed }} />
            </Col>
          </Grid>
        </div>
      )
    })
  }
}

export const query = graphql`
  fragment Paragraphs on node__page {
    relationships {
      paragraphs: field_paragraphs {
        id
        ... on paragraph__text_photo {
          body: field_body {
            processed
          }
          relationships {
            field_image {
              id
              localFile {
                childImageSharp {
                  fluid(maxWidth: 613, maxHeight: 495) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
